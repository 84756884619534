<template>
  <documents-loader
    url="/api/docs/information"
    :update.sync="update"
  />
</template>

<script>
import DocumentsLoader from '@/components/Document/DocumentsLoader'

export default {
  name: 'Info',
  components: { DocumentsLoader },
  data: () => ({
    update: false
  }),
  mounted () {
    this.update = true
  }
}
</script>
